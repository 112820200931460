import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";
import { ToggleSwitch } from "clutch/src/ToggleSwitch/ToggleSwitch.jsx";

import AbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import { diffFallback } from "@/game-val/MapMenuListItem.jsx";
import BlitzFilter from "@/inline-assets/blitz-filter.svg";
import BlitzCloseIcon from "@/inline-assets/close-icon.svg";

const difficulties = ["easy", "medium", "pro"];

const TipsFilters = styled("div")`
  padding: 0 var(--sp-6);
  height: calc(var(--sp-1) * 23);
  display: flex;
  align-items: center;
  flex-shrink: 0;

  ${mobile} {
    padding: 0 var(--sp-4);
    height: calc(var(--sp-1) * 18);
  }

  .filter-frame {
    position: relative;
    width: 100%;
  }

  .type-body2-form--active {
    font-size: var(--sp-3_5);
  }

  .buttons {
    display: flex;
  }

  .btn {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: var(--sp-3);
    height: var(--sp-9);
    padding: 0 var(--sp-4);
    text-align: center;
    background: var(--shade6);
    text-shadow: var(--btn-txt-shadow);
    box-shadow: var(--highlight);
    border-radius: ${({ borderRadius }) => borderRadius || "var(--br)"};
    cursor: pointer;
    transition: var(--transition);

    &:hover {
      background: var(--shade5);
    }

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: var(--sp-11);
    color: var(--shade3);
    background: var(--shade7);
    border-radius: 0;
    cursor: pointer;

    &:hover {
      background: var(--shade6);
    }

    &:not(:last-child) {
      margin-right: var(--sp-px);
    }

    &:first-child {
      border-top-left-radius: var(--br);
      border-bottom-left-radius: var(--br);
    }
    &:last-child {
      border-top-right-radius: var(--br);
      border-bottom-right-radius: var(--br);
    }

    &.active {
      color: var(--shade0);
      background: var(--shade5);
    }

    &.btn-filter {
      flex: unset;

      svg {
        height: var(--sp-6);
        width: var(--sp-11);
      }
    }
  }
`;

const FilterMenu = styled("div")`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  padding: var(--sp-4);
  width: 100%;
  background: var(--shade10);
  border-radius: var(--br);
  z-index: 1;
  box-shadow:
    0 7px 5px rgba(0, 0, 0, 0.07),
    0 22px 18px rgba(0, 0, 0, 0.1),
    0 100px 80px rgba(0, 0, 0, 0.2);

  .filter-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sp-4);

    .close {
      color: var(--shade3);
      width: var(--sp-5);
      -webkit-appearance: unset;
      background: transparent;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        color: var(--shade1);
      }
    }
  }

  .filter-grp {
    &:not(:last-child) {
      margin-bottom: var(--sp-5);
    }
  }

  .filter-grp-title {
    color: var(--shade3);
    margin-bottom: var(--sp-2);
  }
  .filter-btn {
    background: var(--shade8);
    pointer-events: none;
    user-select: none;
    opacity: 0.1;

    &.available {
      pointer-events: all;
      opacity: 1;
      cursor: pointer;

      &:hover {
        color: var(--shade2);
        background: var(--shade7);
      }
    }

    &.inactive {
      opacity: 0.65;
      background: var(--shade8);
    }
    &.active {
      color: var(--shade1);
      background: var(--shade7);

      &:hover {
        color: var(--shade0);
        background: var(--shade6);
      }
    }

    svg {
      width: var(--sp-9);
    }
  }

  .filter-headshot {
    display: flex;
    align-items: center;
    & > div:nth-child(1) {
      margin-right: 4em;
    }
  }
`;

const AgentAbilities = styled("div")`
  display: flex;
  align-items: center;
  margin: 0 - var(--sp-1);

  .ability-btn {
    flex: 1;
    display: grid;
    align-items: center;
    justify-content: center;
    margin: 0 var(--sp-1);
    padding: var(--sp-2);
    color: var(--shade3);
    border-radius: var(--br);
  }
`;

const DifficultyPills = styled("div")`
  display: flex;
  flex-wrap: wrap;

  .pill {
    display: flex;
    align-items: center;
    padding: var(--sp-1_5) var(--sp-3);
    margin-right: var(--sp-1);
    background: var(--shade7);
    border-radius: calc(var(--sp-1) * 10);
  }
`;

const FilterMenuOverlay = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--shade7);
  opacity: 0.7;
  z-index: 1;
`;

const MapMenuFilterButtons = ({
  agent,
  tipsSide,
  updateFilters,
  tipFilters,
  setTipFilters,
}) => {
  const { t } = useTranslation();
  const [changeFilters, setChangeFilters] = useState(false);

  const handleSideSelect = useCallback(
    (e) => {
      updateFilters("side", e.currentTarget.dataset.side);
    },
    [updateFilters],
  );

  const handleFilterClick = useCallback(
    (clickedType, clickedValue) => {
      updateFilters("guide", null);
      const prevVal = tipFilters[clickedType];
      const newVal =
        prevVal === "all"
          ? [clickedValue]
          : prevVal.includes(clickedValue)
            ? prevVal.filter((val) => val !== clickedValue)
            : [...prevVal, clickedValue];
      setTipFilters({
        ...tipFilters,
        [clickedType]: newVal,
      });
    },
    [updateFilters, setTipFilters, tipFilters],
  );

  return (
    <TipsFilters>
      {changeFilters && (
        <FilterMenuOverlay onClick={() => setChangeFilters(false)} />
      )}
      <div className="filter-frame">
        <div className="buttons">
          <div
            className={`btn ${tipsSide === "attacking" && "active"}`}
            data-side="attacking"
            onClick={handleSideSelect}
          >
            <span className="type-body2-form--active">
              {t("common:attacking", "Attacking")}
            </span>
          </div>
          <div
            className={`btn ${tipsSide === "defending" && "active"}`}
            data-side="defending"
            onClick={handleSideSelect}
          >
            <span className="type-body2-form--active">
              {t("common:defending", "Defending")}
            </span>
          </div>
          <div
            className={`btn btn-filter ${changeFilters && "active"}`}
            onClick={() => setChangeFilters(!changeFilters)}
          >
            <BlitzFilter />
          </div>
        </div>
        {changeFilters && (
          <FilterMenu>
            <div className="filter-header">
              <span className="type-subtitle2">
                {t("common:filter", "Filter")}
              </span>
              <button className="close" onClick={() => setChangeFilters(false)}>
                <BlitzCloseIcon />
              </button>
            </div>
            {agent ? (
              <>
                <div className="filter-grp">
                  <span className="type-overline filter-grp-title">
                    {t("common:difficulty", "Difficulty")}
                  </span>
                  <DifficultyPills>
                    {difficulties.map((difficulty) => {
                      const diffActive =
                        tipFilters.difficulty === "all" ||
                        tipFilters.difficulty.includes(difficulty);
                      return (
                        <div
                          key={difficulty}
                          className={`filter-btn pill available ${
                            diffActive ? "active" : "inactive"
                          }`}
                          onClick={() =>
                            handleFilterClick("difficulty", difficulty)
                          }
                        >
                          <DifficultyDot difficulty={difficulty} />
                          <span className="type-caption--bold">
                            {t(
                              `common:${difficulty}`,
                              diffFallback[difficulty],
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </DifficultyPills>
                </div>

                <div className="filter-grp">
                  <span className="type-overline filter-grp-title">
                    {t("common", "Abilities")}
                  </span>
                  <AgentAbilities>
                    {agent.abilities.map((agentAbility) => {
                      const abilityActive =
                        tipFilters.abilities === "all" ||
                        tipFilters.abilities.includes(agentAbility.key);
                      return (
                        <div
                          key={agentAbility.key}
                          className={`filter-btn ability-btn available ${
                            abilityActive ? "active" : "inactive"
                          }`}
                          onClick={() =>
                            handleFilterClick("abilities", agentAbility.key)
                          }
                        >
                          <AbilityIcon ability={agentAbility.key} />
                        </div>
                      );
                    })}
                  </AgentAbilities>
                </div>
              </>
            ) : (
              <div className="filter-headshot">
                <ToggleSwitch
                  value={tipFilters.isEnabledHeadshot}
                  labelText={t(
                    "val:guides.headshotGallery",
                    "Headshot Gallery",
                  )}
                  onChange={() => {
                    setTipFilters({
                      ...tipFilters,
                      isEnabledHeadshot: !tipFilters.isEnabledHeadshot,
                    });
                    setChangeFilters(false);
                  }}
                />
              </div>
            )}
          </FilterMenu>
        )}
      </div>
    </TipsFilters>
  );
};

export default MapMenuFilterButtons;
