import React, { useCallback } from "react";
import { styled } from "goober";

import AgentAbilityIcon from "@/game-val/AgentAbilityIcon.jsx";
import DifficultyDot from "@/game-val/DifficultyDot.jsx";
import ShotSpotIcon from "@/game-val/HeadshotSpotIcon.jsx";
import ShotTargetIcon from "@/game-val/HeadshotTargetIcon.jsx";
import ValHeadshot from "@/inline-assets/val-headshot.svg";

const AgentAbility = styled("div")`
  transform: translate(-50%, -50%);
  transform: ${({ $mapRotation }) =>
    $mapRotation
      ? `translate(-50%, -50%) rotate(${$mapRotation * -1}deg)`
      : "translate(-50%, -50%)"};
  position: absolute;
  box-sizing: border-box;
  border: var(--sp-0_5) solid transparent;
  border-radius: 50%;
  cursor: pointer;

  &:hover,
  &.hover {
    background: var(--agent-color-transparent);
    border-color: var(--agent-color);
    transform: ${({ $mapRotation }) =>
      $mapRotation
        ? `translate(-50%, -50%) rotate(${$mapRotation * -1}deg) scale(1.1)`
        : "translate(-50%, -50%) scale(1.1)"};
    z-index: 2;

    svg {
      color: var(--agent-color-light) !important;
      opacity: 1 !important;
    }
  }

  &.selected {
    background: var(--agent-color-transparent);
    border-color: var(--agent-color);
    transform: ${({ $mapRotation }) =>
      $mapRotation
        ? `translate(-50%, -50%) rotate(${$mapRotation * -1}deg) scale(1.3)`
        : "translate(-50%, -50%) scale(1.3)"};
    z-index: 2;

    svg {
      color: var(--agent-color-light) !important;
      opacity: 1 !important;
    }
  }

  svg {
    color: var(--agent-color-light);
    height: var(--sp-7);
    width: var(--sp-7);
    font-size: var(--sp-7);

    .tip-focused & {
      opacity: 0.5;
    }
  }

  .difficulty-dot {
    position: absolute;
    bottom: var(--sp-0_5);
    right: var(--sp-0_5);
    box-shadow: 0 0 0 var(--sp-0_5) var(--shade9);
  }
`;

const MapDotFrame = styled("div")`
  transform: translate(-50%, -50%);
  position: absolute;
  cursor: pointer;

  &:hover,
  &.hover {
    filter: brightness(1.2);
    z-index: 2;

    .dot {
      transform: ${({ $mapRotation }) =>
        $mapRotation
          ? `rotate(${$mapRotation * -1}deg) scale(1.1)`
          : "translate(-50%, -50%) scale(1.1)"};
    }
  }

  .dot {
    position: relative;
    height: var(--sp-2_5);
    width: var(--sp-2_5);
    border-radius: 50%;
    background: linear-gradient(to top, var(--shade1), var(--shade0))};
    transform: ${({ $mapRotation }) =>
      $mapRotation
        ? `rotate(${$mapRotation * -1}deg)`
        : "translate(-50%, -50%)"};
    box-shadow: 0 0 var(--sp-3) 9px var(--shade9);

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      border: var(--sp-px) solid var(--shade0);
    }

    &:before {
      height: 140%;
      width: 140%;
      opacity: 0.6;
    }
    &:after {
      height: 200%;
      width: 200%;
      opacity: 0.4;
    }
  }
`;

const HeadshotRange = styled("div")`
  position: absolute;
  height: var(--sp-px);
  background: rgb(187, 201, 224);
  background: radial-gradient(
    circle,
    rgba(187, 201, 224, 1) 0%,
    rgba(187, 201, 224, 0.8) 30%,
    rgba(187, 201, 224, 0.5) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  transform-origin: left;
`;

const HeadshotSpotIconWrapper = styled("div")`
  position: absolute;
  margin-top: -0.875rem;
  margin-left: -0.875rem;
`;

const HeadshotTargetIconWrapper = styled("div")`
  position: absolute;
  margin-top: calc(var(--sp-3) * -1);
  margin-left: calc(var(--sp-3) * -1);

  svg {
    width: var(--sp-6);
    height: var(--sp-6);
  }
`;

function getIconPosition(positionX, positionY) {
  return {
    top: `${positionY}%`,
    left: `${positionX}%`,
  };
}

const Icon = ({
  className,
  tip,
  positionX,
  positionY,
  isTipSelected,
  updateFilters,
  hoveredTip,
  setHoveredTip,
  mapEl,
  mapRotation,
  isHeadshot,
  location,
}) => {
  const { id, title, agent, icon, difficulty } = tip;

  const handleTipClick = useCallback(
    (e) => {
      const isSelected = e.currentTarget?.dataset?.selected;
      const mapwidth = mapEl.current?.clientWidth;

      if (isSelected === "true") {
        updateFilters("guide", null);
      } else if (mapwidth) {
        updateFilters("guide", id);
      }
    },
    [mapEl, updateFilters, id],
  );

  const handleMouseOver = useCallback(
    (e) => {
      const tip_title = e.currentTarget.dataset.title;
      setHoveredTip(tip_title);
    },
    [setHoveredTip],
  );

  const handleMouseOut = useCallback(() => {
    setHoveredTip(null);
  }, [setHoveredTip]);

  const iconPosition = getIconPosition(positionX, positionY);

  const tipClasses = `${hoveredTip === title ? "hover" : ""} ${className}`;

  if (agent) {
    return (
      <AgentAbility
        style={iconPosition}
        className={tipClasses}
        onClick={handleTipClick}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        data-selected={isTipSelected}
        data-title={title}
        data-x={positionX}
        data-y={positionY}
        $mapRotation={mapRotation}
      >
        <AgentAbilityIcon ability={icon} />
        <DifficultyDot difficulty={difficulty} />
      </AgentAbility>
    );
  }
  if (isTipSelected && isHeadshot) {
    return (
      <>
        <HeadshotRange
          style={{
            top: `${location.shotIcon.y}%`,
            left: `${location.shotIcon.x}%`,
            width: `${location.shotRange}%`,
            transform: `rotate(${location.angle}deg)`,
          }}
        />

        <HeadshotSpotIconWrapper
          style={{
            top: `${location.shotIcon.y}%`,
            left: `${location.shotIcon.x}%`,
          }}
        >
          <ShotSpotIcon />
        </HeadshotSpotIconWrapper>

        <HeadshotTargetIconWrapper
          style={{
            top: `${location.targetIcon.y}%`,
            left: `${location.targetIcon.x}%`,
          }}
        >
          <ValHeadshot />
        </HeadshotTargetIconWrapper>
      </>
    );
  }
  return (
    <MapDotFrame
      style={iconPosition}
      className={tipClasses}
      onClick={handleTipClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      data-selected={isTipSelected}
      data-title={title}
      data-x={positionX}
      data-y={positionY}
      $mapRotation={mapRotation}
    >
      {isHeadshot ? <ShotTargetIcon /> : <div className={"dot"} />}
    </MapDotFrame>
  );
};

export default Icon;
