import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import {
  TransformComponent,
  TransformWrapper,
} from "@pronestor/react-zoom-pan-pinch";
import { mobile } from "clutch/src/Style/style.mjs";
import i18n from "i18next";

import { readState } from "@/__main__/app-state.mjs";
import { showSnackbar } from "@/app/actions.mjs";
import { onVolumeChange } from "@/game-val/actions.mjs";
import { AGENT_COLORS, OUT_OF_ROTATION_MAPS } from "@/game-val/constants.mjs";
import { getGuidesData, getSrc } from "@/game-val/guides-utils.mjs";
import ShotTargetIcon from "@/game-val/HeadshotTargetIcon.jsx";
import {
  getParams,
  getParamsStr,
  MAP_VIEW_SETTINGS,
  updateParams,
} from "@/game-val/map-utils.mjs";
import MapMenu from "@/game-val/MapMenu.jsx";
import TipIcon from "@/game-val/MapTipIcon.jsx";
import VideoFrame from "@/shared/VideoFrame.jsx";
import MapControls from "@/shared-fps/MapControls.jsx";
import keyInObject from "@/util/key-in-object.mjs";
import { useRoute } from "@/util/router-hooks.mjs";
import useFullWidthPage from "@/util/use-full-width-page.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const OPTIONS = {
  defaultScale: 1,
  limitToBounds: false,
  maxScale: 5,
  minScale: 0.5,
  wheel: {
    step: 1.1,
  },
};

const MAP_VIEW_OPTIONS = [
  {
    key: "neutral",
    label: ["val:neutral", "Neutral"],
  },
  {
    key: "labels",
    label: ["val:labels", "Labels"],
  },
  {
    key: "walls",
    label: ["val:walls", "Walls"],
  },
];

export const MAP_ANIMATION_DURATION_MS = 250;
export const MAP_ZOOM = 1.5;

const MapContentWrapper = styled("div")`
  --menu-width: calc(var(--sp-1) * 100);

  position: absolute;
  left: 0;
  right: 0;
  height: 100%;
  display: flex;

  ${mobile} {
    margin: 0 -1%;
    flex-direction: column;
    min-height: 100%;
  }

  ${({ $agentColor }) => {
    if ($agentColor) {
      const agentColorsArr = $agentColor.split(", ");
      return `
--agent-color-h: ${agentColorsArr[0]};
--agent-color-s: ${agentColorsArr[1]};
--agent-color-l: ${agentColorsArr[2]};
--agent-color: hsl(
var(--agent-color-h),
var(--agent-color-s),
var(--agent-color-l)
);
--agent-color-light: hsl(
var(--agent-color-h),
var(--agent-color-s),
calc(var(--agent-color-l) / 0.9)
);
--agent-color-dark: hsl(
  var(--agent-color-h),
  var(--agent-color-s),
  calc(var(--agent-color-l) * 0.9)
  );
--agent-color-transparent: hsla(var(--agent-color), 0.2);
--agent-color-transparent-light: hsla(var(--agent-color), 0.75);
`;
    }
  }};
`;

const MapWindow = styled("div")`
  position: absolute;
  left: var(--menu-width);
  right: 0px;
  top: 0px;
  bottom: 0px;

  ${mobile} {
    display: none;
  }
`;

const Content = styled("div")`
  cursor: grab;
  height: 100%;

  .react-transform-wrapper {
    height: 100%;
    width: 100%;
  }

  .react-transform-component {
    margin: 0 auto;
  }

  ${mobile} {
    display: flex;
    align-items: center;
    min-height: calc(100% - var(--title-bar-height));
  }

  &:active {
    cursor: grabbing;
  }

  > div {
    ${mobile} {
      overflow: visible;
    }
  }

  .map-image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: auto;

    ${mobile} {
      height: auto;
      width: 100vw;
    }
  }
  .map-labels {
    opacity: 0.8;
    transition: ${MAP_ANIMATION_DURATION_MS}ms ease-in-out;

    .tip-focused &,
    .agent-selected & {
      opacity: 0.25;
    }
  }
`;

const MapPlaceholder = styled("svg")`
  display: block;
  width: auto;

  ${mobile} {
    height: auto;
    width: 100%;
  }
`;

const MapFrame = styled("div", forwardRef)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  ${mobile} {
    width: 100%;
    height: auto;
  }
`;
const MapFrameInner = styled("div")`
  position: relative;

  img.layout-image,
  div.tips-frame {
    transform: ${({ $rotationDegree }) => `rotate(${$rotationDegree}deg)`};
  }

  ${mobile} {
    height: auto;
    width: 100%;
  }

  .tips-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const SelectedTipContent = styled("div")`
  position: absolute;
  top: var(--sp-3);
  right: var(--sp-3);
  width: 30%;
  z-index: 4;

  ${mobile} {
    position: sticky;
    width: 100%;
    top: unset;
    bottom: var(--page-padding);
    right: 0;
  }
`;

const HeadshotLabel = styled("div")`
  position: absolute;
  top: var(--sp-8);
  left: var(--sp-8);
  display: flex;
`;

const tipsFilters = {
  type: "all",
  difficulty: "all",
  abilities: "all",
};

const HeadshotTip = styled("div")`
  width: calc(var(--sp-1) * 55);
  padding-left: var(--sp-2);
  color: var(--shade2);
`;

function MapContent({ zoomIn, zoomOut, setTransform }) {
  useFullWidthPage();
  const { t } = useTranslation();
  const {
    searchParams,
    currentPath,
    parameters: [map],
  } = useRoute();
  const mapEl = useRef(null);
  const mapFrameEl = useRef(null);
  const videoRef = useRef();

  const {
    val: {
      guides,
      meta: {
        maps: { list: mapsList },
        agents: { list: agentsList },
      },
    },
  } = useSnapshot(readState);

  const {
    settings: {
      valorant: { muted },
    },
  } = useSnapshot(readState);

  const { list: videosList = [] } = getGuidesData(
    guides,
    getParamsStr(searchParams, map),
  );

  const { guide, agent, side, isHeadshot } = getParams(searchParams);

  const [hoveredTip, setHoveredTip] = useState(null);
  const [tipFilters, setTipFilters] = useState({
    ...tipsFilters,
    isEnabledHeadshot: isHeadshot,
  });

  useEffect(() => {
    if (!OUT_OF_ROTATION_MAPS.includes(map)) return;
    showSnackbar({
      priority: "low",
      id: "valorant-map-out-of-rotation",
      text: [
        "valorant:mapPoolRotation",
        "Map is currently out of map pool rotation. More insights coming soon.",
      ],
      dismissable: false,
      actions: [
        {
          text: ["common:ok", "OK"],
          dismiss: true,
          emphasis: "low",
          textColor: "var(--turq)",
          textColorHover: "hsla(var(--turq-hsl) / 0.75)",
        },
      ],
      dismissOnRouteChange: (route) => {
        const mapInRoute = route.searchParams.get("map");
        return !OUT_OF_ROTATION_MAPS.includes(mapInRoute);
      },
    });
  }, [map]);

  const updateFilters = useCallback(
    (key, val) => {
      updateParams({ [key]: val, searchParams, currentPath });
      setTipFilters(tipFilters);
      setTransform(0, 0, 1, MAP_ANIMATION_DURATION_MS, "easeInOutCubic");
    },
    [searchParams, currentPath, tipFilters, setTransform],
  );

  // View Settings
  const [viewSettings, setViewSettings] = useState(MAP_VIEW_SETTINGS);

  const filteredGuides = useMemo(() => {
    const headshotsList = guides?.headshots?.list || [];

    const guidesList = tipFilters.isEnabledHeadshot
      ? headshotsList
      : videosList;

    return guidesList.filter((g) => {
      let condition = g.map === map;

      if (!tipFilters.isEnabledHeadshot) {
        condition = g.agent === agent;
      }
      if (condition && tipFilters.abilities !== "all") {
        condition = tipFilters.abilities.includes(g.icon);
      }
      if (condition && tipFilters.type !== "all") {
        condition = tipFilters.type.includes(g.side);
      }
      if (condition && tipFilters.difficulty !== "all") {
        condition = tipFilters.difficulty.includes(g.difficulty);
      }
      return condition;
    });
  }, [
    guides?.headshots?.list,
    tipFilters.isEnabledHeadshot,
    tipFilters.abilities,
    tipFilters.type,
    tipFilters.difficulty,
    videosList,
    map,
    agent,
  ]);

  const filteredAgentsList = useMemo(() => {
    return agentsList
      .filter((a) => {
        return a.key !== "kay/o";
      })
      .sort((a, b) => (a.key > b.key ? 1 : -1));
  }, [agentsList]);

  const selectedAgent = filteredAgentsList.find((a) => a.key === agent);
  const { name, images = {} } = mapsList.find((o) => o.key === map) || {};

  const mapOrientation = viewSettings.neutral ? "neutral" : side;
  const rotationDegree = images.side_orientations?.[side];

  const selectedGuide = filteredGuides.find((g) => g.id === guide);
  const type = tipFilters.isEnabledHeadshot ? "headshots" : "video";
  const src = getSrc(selectedGuide, type);

  const tipsFilterOptions = useMemo(() => {
    const agents = [];
    const difficulty = [];
    const abilities = [];
    const type = [];

    filteredGuides.forEach((g) => {
      if (g.agent) {
        agents.push(g.agent);
      }
      if (g.difficulty) {
        difficulty.push(g.difficulty);
      }
      if (g.icon) {
        abilities.push(g.icon);
      }
      if (g.type) {
        type.push(g.type);
      }
    });

    return {
      difficulty: [...new Set(difficulty)],
      type: [...new Set(type)],
      abilities: [...new Set(abilities)],
      agents: [...new Set(agents)],
      hasHeashot: false,
    };
  }, [filteredGuides]);

  const agentColor = keyInObject(AGENT_COLORS, agent)
    ? AGENT_COLORS[agent]
    : null;

  return (
    <MapContentWrapper
      style={{ "--agent-color": agentColor ? `hsl(${agentColor})` : null }}
    >
      <MapMenu
        mapName={name}
        mapHero={images.hero}
        agents={filteredAgentsList}
        maps={mapsList}
        tips={filteredGuides}
        tipsSide={side}
        selectedAgent={selectedAgent}
        selectedTip={selectedGuide}
        hoveredTip={hoveredTip}
        setHoveredTip={setHoveredTip}
        tipFilters={tipFilters}
        setTipFilters={setTipFilters}
        tipsFilterOptions={tipsFilterOptions}
        updateFilters={updateFilters}
      />

      <MapWindow>
        {tipFilters.isEnabledHeadshot && (
          <HeadshotLabel>
            <ShotTargetIcon />
            <HeadshotTip className="type-body2">
              {t(
                "val:tips.headshotTipExplanation",
                "These are common places where you can find people vulnerable for headshots",
              )}
            </HeadshotTip>
          </HeadshotLabel>
        )}

        <Content>
          <TransformComponent>
            <MapFrame ref={mapFrameEl}>
              <MapFrameInner $rotationDegree={rotationDegree}>
                <MapPlaceholder height="800" width="800" />

                <img
                  src={images.layout}
                  className="map-image layout-image"
                  ref={mapEl}
                />
                {viewSettings.walls && images.walls?.[mapOrientation] && (
                  <img
                    src={images.walls[mapOrientation]}
                    className="map-image"
                  />
                )}
                {viewSettings.labels && images.labels?.[mapOrientation] && (
                  <img
                    src={images.labels[mapOrientation]}
                    className="map-image map-labels"
                  />
                )}
                <div
                  className="tips-frame"
                  style={{
                    transform: `rotate(${images?.side_orientations?.[mapOrientation]}deg)`,
                  }}
                >
                  {filteredGuides.map((tip) => {
                    if (!images.side_orientations) return null;

                    const isSelected = tip.id === guide;
                    if (
                      tipFilters.isEnabledHeadshot &&
                      selectedGuide &&
                      !isSelected
                    )
                      return null;
                    return (
                      <TipIcon
                        className={isSelected && "selected"}
                        key={tip.id}
                        tip={tip}
                        tipsSide={side}
                        isTipSelected={isSelected}
                        updateFilters={updateFilters}
                        hoveredTip={hoveredTip}
                        setHoveredTip={setHoveredTip}
                        positionX={
                          tipFilters.isEnabledHeadshot
                            ? tip.location.targetIcon.x
                            : tip.location.icon.x
                        }
                        positionY={
                          tipFilters.isEnabledHeadshot
                            ? tip.location.targetIcon.y
                            : tip.location.icon.y
                        }
                        mapEl={mapEl}
                        mapFrameEl={mapFrameEl}
                        neutralMap={viewSettings.neutral}
                        neutralOrientation={images.side_orientations.neutral}
                        mapRotation={images.side_orientations[mapOrientation]}
                        isHeadshot={tipFilters.isEnabledHeadshot}
                        location={tip.location}
                      />
                    );
                  })}
                </div>
              </MapFrameInner>
            </MapFrame>
          </TransformComponent>
        </Content>

        <MapControls
          viewSettings={viewSettings}
          setViewSettings={setViewSettings}
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          options={MAP_VIEW_OPTIONS}
        />
      </MapWindow>

      {src && (
        <SelectedTipContent>
          <VideoFrame
            videoRef={videoRef}
            src={src}
            onClose={() => {
              updateFilters("guide", null);
            }}
            isMuted={muted}
            onVolumeChange={onVolumeChange}
          />
        </SelectedTipContent>
      )}
    </MapContentWrapper>
  );
}

function Map() {
  return (
    <TransformWrapper {...OPTIONS}>
      {({ zoomIn, zoomOut, setTransform }) => (
        <MapContent
          zoomIn={zoomIn}
          zoomOut={zoomOut}
          setTransform={setTransform}
        />
      )}
    </TransformWrapper>
  );
}

Map.fullBleed = true;

export default Map;

export function meta([tab]) {
  const { t } = i18n;
  const map =
    (readState.val.meta?.maps?.list || []).find((o) => o.key === tab)?.name ||
    tab;
  const mapName = t(`val:maps.${tab}`, map);

  return {
    title: ["val:meta.map.title", "Interactive {{mapName}} Guide", { mapName }],
    description: [
      "val:meta.map.description",
      "Learn the ins and outs of every VALORANT map {{mapName}} with our interactive attacking and defending maps.",
      { mapName },
    ],
  };
}
