import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import AgentCard from "@/game-val/AgentCard.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import BlitzCircleCheckOutline from "@/inline-assets/blitz-circle-check-outline.svg";
import BlitzClose from "@/inline-assets/close.svg";
import keyInObject from "@/util/key-in-object.mjs";

const Container = styled("div")`
  position: absolute;

  width: calc(100% - var(--sp-3) * 2);
  height: calc(100% - var(--sp-3) * 2);
  margin: var(--sp-3);

  border-radius: var(--br);

  overflow: hidden;

  z-index: 1;
`;

const Frame = styled("div")`
  max-height: 100%;
  padding: var(--sp-3);

  background-color: var(--shade9);

  box-shadow:
    0 7px 5px rgba(0, 0, 0, 0.07),
    0 22px 18px rgba(0, 0, 0, 0.1),
    0 100px 80px rgba(0, 0, 0, 0.2);

  overflow-y: auto;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: var(--sp-3);

    &:not(:first-of-type) {
      margin-top: var(--sp-4);
    }

    .close {
      color: var(--shade3);
      font-size: var(--sp-5);
      -webkit-appearance: unset;
      background: transparent;
      border: none;
      outline: none;
      margin: 0;
      padding: 0;
      cursor: pointer;

      &:hover {
        color: var(--shade1);
      }
    }
  }

  .agent-row {
  }
`;

const AgentRow = styled("div")`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--shade7);
  padding-right: var(--sp-4);
  margin-bottom: var(--sp-1);
  cursor: pointer;

  &.agent-unavailable {
    opacity: 0.2;
    pointer-events: none;
  }

  > * {
    position: relative;
  }

  .agent-info {
    display: flex;
    align-items: center;
  }

  &:hover,
  &.current-agent {
    &::before {
      background: var(--agent-color, var(--shade0));
      opacity: 0.15;
    }
    .agent-textwall-container {
      svg {
        fill: var(--agent-color, var(--shade0));
        stroke: transparent;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--agent-color, var(--shade0));
    border-radius: var(--br);
    opacity: 0;
    transition: var(--transition);
  }

  .agent-textwall-container {
    svg {
      fill: transparent;
      stroke-width: 5px;
      stroke: var(--shade5);
    }
  }
  .agent-name {
    margin-left: var(--sp-2);
  }
  .agent-selected {
    width: var(--sp-6);
    color: var(--agent-color, var(--shade0));
  }
`;

const MapsBlocks = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: var(--sp-1);
`;
const MapItem = styled("span")`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--sp-4) var(--sp-4);
  padding-top: calc(var(--sp-1) * 17);
  text-align: center;
  background: var(--shade8);
  border-radius: var(--br);
  overflow: hidden;

  ${mobile} {
    padding-top: var(--sp-2);
  }

  &.current-map {
    pointer-events: none;

    .map-image {
      opacity: 0.5;
    }
  }

  &:hover {
    .map-image {
      transform: scale(1.1);
    }
  }

  > * {
    position: relative;
  }

  .map-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: auto 100%;
    background-position: center 22%;
    border-radius: var(--br);
    mask-image: linear-gradient(to bottom, black, transparent);
    transition: var(--transition);
  }

  > svg {
    position: absolute;
    top: var(--sp-2);
    right: var(--sp-2);
    width: var(--sp-6);
  }
`;

const ContentSelector = ({
  type,
  agents,
  maps,
  mapCurrent,
  updateFilters,
  setPickAgent,
  selectedAgent,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Frame className={className}>
        {type === "agents" ? (
          <>
            <div className="header">
              <span className="type-subtitle2">
                {t("val:selectMap", "Select a Map")}
              </span>
              <button className="close" onClick={() => setPickAgent(false)}>
                <BlitzClose />
              </button>
            </div>

            <MapsBlocks className="desktop">
              {maps.map((map) => (
                <MapBlock
                  key={map.key}
                  mapKey={map.key}
                  name={map.name}
                  hero={map.images?.hero}
                  mapCurrent={mapCurrent}
                  updateFilters={updateFilters}
                />
              ))}
            </MapsBlocks>

            <div className="header">
              <span className="type-subtitle2">
                {t("val:selectAgent", "Select an agent")}
              </span>
            </div>
            <div>
              {agents?.map((agent) => {
                const agentColor = keyInObject(AGENT_COLORS, agent.key)
                  ? AGENT_COLORS[agent.key]
                  : null;
                const cutout = agent.images?.cutout || null;
                return (
                  <AgentRow
                    key={agent.key}
                    className={
                      selectedAgent?.key === agent.key && "current-agent"
                    }
                    onClick={() => {
                      setPickAgent(false);
                      updateFilters("agent", agent.key);
                    }}
                    style={{
                      "--agent-color": agentColor ? `hsl(${agentColor})` : null,
                    }}
                  >
                    <div className="agent-info">
                      <AgentCard
                        agentKey={agent.key}
                        agentImage={cutout}
                        width={80}
                        noBG
                      />
                      <span className="type-body2-form--active agent-name">
                        {agent.name}
                      </span>
                    </div>
                    {selectedAgent?.key === agent.key && (
                      <BlitzCircleCheckOutline className="agent-selected" />
                    )}
                  </AgentRow>
                );
              })}
            </div>
          </>
        ) : type === "maps" ? (
          <>
            <div className="header">
              <span className="type-subtitle2">
                {t("val:selectMap", "Select a Map")}
              </span>
              <button className="close" onClick={() => setPickAgent(false)}>
                <BlitzClose />
              </button>
            </div>
            <MapsBlocks className="desktop">
              {maps.map((map) => (
                <MapBlock
                  key={map.key}
                  mapKey={map.key}
                  name={map.name}
                  hero={map.images?.hero}
                  mapCurrent={mapCurrent}
                  updateFilters={updateFilters}
                />
              ))}
            </MapsBlocks>
          </>
        ) : null}
      </Frame>
    </Container>
  );
};

export default ContentSelector;

const MapBlock = ({ mapKey, name, hero, mapCurrent, updateFilters }) => {
  const mapImg = hero;
  const isCurrentMap = name === mapCurrent;

  return (
    <MapItem
      className={isCurrentMap && "current-map"}
      onClick={() => updateFilters("map", mapKey)}
    >
      <div
        className="map-image"
        style={{ backgroundImage: `url(${mapImg})` }}
      />
      <span className="type-body2-form--active">{name}</span>
      {isCurrentMap && <BlitzCircleCheckOutline />}
    </MapItem>
  );
};
