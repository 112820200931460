import React from "react";
import { styled } from "goober";

import ValHeadshot from "@/inline-assets/val-headshot.svg";

const ShotTargetWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--sp-4);
  height: var(--sp-4);
  background: linear-gradient(
    0deg,
    var(--shade2) -22.91%,
    var(--shade0) 111.37%
  );
  border-radius: 50%;

  svg {
    width: var(--sp-3);
    height: var(--sp-3);

    path {
      fill: var(--shade6);
    }
  }
`;

const ShotTargetIcon = () => {
  return (
    <ShotTargetWrapper>
      <ValHeadshot />
    </ShotTargetWrapper>
  );
};

export default ShotTargetIcon;
