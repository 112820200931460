import React, { forwardRef, useRef } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { Button } from "clutch/src/Button/Button.jsx";
import { mobile } from "clutch/src/Style/style.mjs";

import { appURLs } from "@/app/app-urls.mjs";
import AgentTextWall from "@/game-val/AgentTextWalls.jsx";
import { AGENT_COLORS } from "@/game-val/constants.mjs";
import MapMenuFilterButtons from "@/game-val/MapMenuFilterButtons.jsx";
import MapMenuHeader from "@/game-val/MapMenuHeader.jsx";
import MapMenuListItem from "@/game-val/MapMenuListItem.jsx";
import { useIsLoaded } from "@/util/router-hooks.mjs";

const MenuFrame = styled("div")`
  position: relative;
  width: var(--menu-width);
  margin-bottom: var(--sp-4);
  border-radius: var(--br-lg);
  min-width: var(--menu-width);
  background: var(--shade9);
  box-shadow: 0 0 25px rgba(8, 7, 19, 0.6);
  display: flex;
  flex-direction: column;

  ${mobile} {
    width: 100%;
    height: 100%;
  }
`;

const MenuContent = styled("div", forwardRef)`
  height: 100%;
  overflow: hidden auto;
  &::-webkit-scrollbar {
    width: var(--sp-3);
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border: var(--sp-1) solid var(--shade7);
    border-radius: var(--br);
  }
`;

const GeneralTitle = styled("span")`
  color: var(--shade1);
  margin-top: var(--sp-6);
  margin-bottom: var(--sp-2);
  padding: 0 var(--sp-6);

  ${mobile} {
    padding: 0 var(--sp-4);
  }
`;
const AgentsTitle = styled("span")`
  display: block;
  color: var(--shade1);
  margin-bottom: var(--sp-3);
  padding: 0 var(--sp-6);

  ${mobile} {
    padding: 0 var(--sp-4);
  }
`;
const Agents = styled("div")`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: var(--sp-12);
  grid-gap: var(--sp-1);
  padding: var(--sp-6);
  padding-bottom: var(--sp-3);
  padding-top: 0;

  ${mobile} {
    padding-left: var(--sp-4);
    padding-right: var(--sp-4);
  }
`;
const Agent = styled("div")`
  --agent-color: var(--agent-color, var(--shade0));
  display: flex;
  align-items: center;
  position: relative;
  border-radius: var(--br);
  cursor: pointer;

  &.agent-unavailable {
    opacity: 0.3;
    pointer-events: none;
  }

  > * {
    position: relative;
  }

  &:hover {
    &::before {
      opacity: 0.3;
    }
    .agent-cutout {
      transform: translate(-50%, 10px) scale(1.4);
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--agent-color);
    opacity: 0.15;
    border-radius: var(--br);
    transition: var(--transition);
  }

  .agent-art {
    position: relative;
    width: 45%;
    height: 100%;
    margin-right: var(--sp-1);
    overflow: hidden;
  }
  svg.agent-textwall {
    fill: var(--agent-color);
    position: absolute;
    left: 50%;
    width: 68px;
    opacity: 0.75;
    transform: translate(-50%, -5%);
  }
  .agent-cutout {
    position: absolute;
    left: 50%;
    bottom: 0;
    width: calc(var(--sp-1) * 20);
    transform: translate(-50%, 8px) scale(1.3);
    transition: var(--transition);
  }
`;

const Tips = styled("ul")`
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 calc(var(--sp-1) * 10);
`;

const NoTips = styled("div")`
  position: relative;
  top: 40%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  margin: 0 var(--sp-24);
  align-items: center;
  text-align: center;
  gap: var(--sp-6);
`;

export default function MapMenu({
  mapName,
  updateFilters,
  mapHero,
  agents,
  maps,
  resetUrl,
  tips,
  tipsSide,
  selectedAgent,
  selectedTip,
  hoveredTip,
  setHoveredTip,
  tipFilters,
  setTipFilters,
  tipsFilterOptions,
}) {
  const { t } = useTranslation();

  const isLoaded = useIsLoaded(true);
  const tipsContent = useRef(null);

  const menuTitle = selectedAgent
    ? `${selectedAgent.name} • ${mapName}`
    : mapName;

  const agentCutoutImage = selectedAgent?.images?.cutout || null;

  const hasTips = tips?.length > 0;
  if (!mapHero) return null;
  return (
    <MenuFrame>
      <MapMenuHeader
        title={menuTitle}
        mapHero={mapHero}
        agentKey={selectedAgent?.key}
        agentImage={agentCutoutImage}
        agents={agents}
        maps={maps}
        mapCurrent={mapName}
        updateFilters={updateFilters}
        selectedAgent={selectedAgent}
        resetUrl={resetUrl}
        tipsFilterOptions={tipsFilterOptions}
      />

      <MapMenuFilterButtons
        mapName={mapName}
        agent={selectedAgent}
        tipsFilterOptions={tipsFilterOptions}
        hasTips={hasTips}
        tipsSide={tipsSide}
        updateFilters={updateFilters}
        tipFilters={tipFilters}
        setTipFilters={setTipFilters}
      />

      <MenuContent ref={tipsContent}>
        {!selectedAgent && !tipFilters.isEnabledHeadshot && (
          <>
            <AgentsTitle className="type-subtitle2">
              {t("val:agentSelect", "Select an agent")}
            </AgentsTitle>
            <Agents>
              {agents.map((agent) => {
                const agentColor = AGENT_COLORS[agent.key];
                const cutout = agent?.images?.cutout;
                const agentAvailable =
                  true || tipsFilterOptions.agents?.includes(agent.key);

                return (
                  <Agent
                    style={{
                      "--agent-color": agentColor ? `hsl(${agentColor})` : null,
                    }}
                    key={agent.name}
                    className={!agentAvailable && "agent-unavailable"}
                    onClick={() => updateFilters("agent", agent.key)}
                  >
                    <div className="agent-art">
                      <AgentTextWall
                        agentKey={agent.key}
                        className="agent-textwall"
                      />
                      {cutout && <img src={cutout} className="agent-cutout" />}
                    </div>
                    <span className="type-body2-form--active">
                      {agent.name}
                    </span>
                  </Agent>
                );
              })}
            </Agents>
          </>
        )}

        {!selectedAgent && hasTips && (
          <GeneralTitle>
            {tipFilters.isEnabledHeadshot
              ? `${tips.length} ${t("val:tips.headshot", "Headshot tips")}`
              : t("val:tips.general", "General tips")}
          </GeneralTitle>
        )}

        {hasTips ? (
          <Tips>
            {tips.map((tip, tipIndex) => (
              <MapMenuListItem
                key={`${tip.title}_${tipIndex}`}
                clickable
                tip={tip}
                tipIndex={tipIndex}
                selectedTip={selectedTip}
                updateFilters={updateFilters}
                hoveredTip={hoveredTip}
                setHoveredTip={setHoveredTip}
                isHeadshot={tipFilters.isEnabledHeadshot}
                agents={agents}
              />
            ))}
          </Tips>
        ) : (
          !hasTips &&
          isLoaded && (
            <NoTips>
              <img
                src={`${appURLs.CDN_PLAIN}/blitz/val/misc/lineups-placeholder.svg`}
              />
              <div>
                <p className="type-subtitle1 shade0">
                  {t("val:lineups.noLineupsFound", "No Lineups Found")}
                </p>
                <p className="type-caption shade1">
                  {t(
                    "val:lineups.noLineupsFoundDescription",
                    "There are currently no lineups available for this agent on this map. Check back soon!",
                  )}
                </p>
              </div>
              <Button onClick={() => updateFilters("agent", null)}>
                {t("val:lineups.goBackToMapLineups", "Go Back to Map Lineups")}
              </Button>
            </NoTips>
          )
        )}
      </MenuContent>
    </MenuFrame>
  );
}
