import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import AgentTextWall from "@/game-val/AgentTextWalls.jsx";
import MapMenuContentSelector from "@/game-val/MapMenuContentSelector.jsx";
import BlitzArrowDropdown from "@/inline-assets/blitz-caret-down.svg";
import BlitzArrowBackIos from "@/inline-assets/chevron-left.svg";

const Header = styled("div")`
  position: relative;
  height: calc(var(--sp-1) * 37);
  background-color: var(--shade10);
  overflow: hidden;
  flex-shrink: 0;

  .header-btn {
    color: var(--shade0);
    opacity: 0.75;
    cursor: pointer;

    &:hover {
      color: var(--shade0);
      opacity: 1;
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.map-header {
    .map-hero {
      mask-image: linear-gradient(to bottom, black, rgba(0, 0, 0, 0.4));
    }
    img {
      width: 100%;
    }
  }
  .pattern-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--agent-color-dark);
    background-blend-mode: multiply;
    mask-image: radial-gradient(ellipse at top, black, rgba(0, 0, 0, 0.4));
  }
  svg.agent-textwall {
    position: absolute;
    width: calc(var(--sp-1) * 65);
    left: 50%;
    fill: var(--agent-color);
    transform: translate(-50%, -20%);
    opacity: 0.8;
  }
  .agent-art {
    position: absolute;
    overflow: hidden;
    left: 0;
    top: 0;
    width: 100%;
    height: calc(var(--sp-1) * 37);
    mask-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), black 40%);

    .agent-image {
      position: absolute;
      bottom: 0;
      width: calc(var(--sp-1) * 75);
      left: 50%;
      transform: translate(-50%, 47px);
    }
  }
`;
const HeaderTitle = styled("div")`
  box-sizing: border-box;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: var(--sp-3) var(--sp-6);
  text-shadow: 0 3px 8px var(--shade10);

  > * {
    position: relative;
  }

  .header-dropdown {
    width: var(--sp-6);
  }
`;

const BackArrow = styled("div")`
  position: absolute;
  top: var(--sp-6);
  left: var(--sp-6);
  font-size: var(--sp-5);
`;

const FilterMenuOverlay = styled("div")`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: var(--shade7);
  opacity: 0.7;
  z-index: 1;
`;

const MapMenuHeader = ({
  title,
  mapHero,
  agentKey,
  agentImage,
  agents,
  maps,
  mapCurrent,
  updateFilters,
  selectedAgent,
  tipsFilterOptions,
}) => {
  const [selecting, setSelecting] = useState(false);

  useEffect(() => {
    setSelecting(false);
  }, [title]);
  const { t } = useTranslation();

  if (agentKey) {
    return (
      <>
        <Header className={selectedAgent && "agent-header"}>
          <div className="agent-art">
            <div className="pattern-bg" />
            <AgentTextWall agentKey={agentKey} className="agent-textwall" />
            {agentImage && <img src={agentImage} className="agent-image" />}
          </div>
          {selectedAgent && (
            <BackArrow
              className="header-btn"
              onClick={() => {
                updateFilters("agent", null);
              }}
            >
              <BlitzArrowBackIos />
            </BackArrow>
          )}
          <HeaderTitle>
            <h6 className="type-h6">
              {t("val:agentMapLineup", "{{agent}} {{map}} Lineup", {
                agent: selectedAgent ? selectedAgent.name : "",
                map: mapCurrent ? mapCurrent.name : "",
              })}
            </h6>
            <div
              className="header-btn header-dropdown"
              onClick={() => setSelecting(true)}
            >
              <BlitzArrowDropdown />
            </div>
          </HeaderTitle>
        </Header>
        {selecting && (
          <>
            <FilterMenuOverlay onClick={() => setSelecting(false)} />
            <MapMenuContentSelector
              type="agents"
              agents={agents}
              maps={maps}
              mapCurrent={mapCurrent}
              setPickAgent={setSelecting}
              selectedAgent={selectedAgent}
              tipsFilterOptions={tipsFilterOptions}
              updateFilters={updateFilters}
            />
          </>
        )}
      </>
    );
  }
  return (
    <>
      <Header className={!selectedAgent && "map-header"}>
        <div className="map-hero">
          <img src={mapHero} alt={`${title} VALORANT Map`} />
        </div>

        <HeaderTitle>
          <h6 className="type-h6">{title}</h6>
          <div
            className="header-btn header-dropdown"
            onClick={() => setSelecting(true)}
          >
            <BlitzArrowDropdown />
          </div>
        </HeaderTitle>
      </Header>
      {selecting && (
        <>
          <FilterMenuOverlay onClick={() => setSelecting(false)} />
          <MapMenuContentSelector
            type="maps"
            maps={maps}
            mapCurrent={mapCurrent}
            setPickAgent={setSelecting}
            updateFilters={updateFilters}
          />
        </>
      )}
    </>
  );
};

export default MapMenuHeader;
