import React from "react";
import { styled } from "goober";

const ShotSpotWrapper = styled("div")`
  position: relative;
  width: var(--sp-6_5);
  height: var(--sp-6_5);
  border-radius: 50%;
  border: var(--sp-px) solid var(--shade3);
  box-sizing: border-box;
  .circle-inner {
    position: absolute;
    top: var(--br-sm);
    left: var(--br-sm);
    width: var(--sp-4_5);
    height: var(--sp-4_5);
    border-radius: 50%;
    border: var(--sp-px) solid var(--shade3);
    box-sizing: border-box;
  }
  .circle-fill {
    position: absolute;
    top: var(--br);
    left: var(--br);
    width: var(--sp-3_5);
    height: var(--sp-3_5);
    border-radius: 50%;
    background: linear-gradient(
      0deg,
      var(--shade2) -22.91%,
      var(--shade0) 111.37%
    );
  }
  &.small {
    width: var(--sp-3);
    height: var(--sp-3);
    .circle-inner {
      top: var(--sp-px);
      left: var(--sp-px);
      width: var(--sp-2);
      height: var(--sp-2);
    }
    .circle-fill {
      top: var(--br-sm);
      left: var(--br-sm);
      width: var(--sp-1);
      height: var(--sp-1);
    }
  }
`;

const ShotSpotIcon = (props) => {
  const { size, ...restProps } = props;

  return (
    <ShotSpotWrapper className={size} {...restProps}>
      <div className="circle-inner" />
      <div className="circle-fill" />
    </ShotSpotWrapper>
  );
};

export default ShotSpotIcon;
